import { FC } from 'react'
import { SvgIcon, SvgIconProps, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import VisuallyHiddenLabel from '@Components/utils/aria/VisuallyHiddenLabel'
import { useSeverityIcon } from '@Hooks/useSeverityIcon'
import { SeverityType } from '@Types/severity'

import * as styles from './styles'

export type MeaIconProps = {
  severity: SeverityType
  exponent?: string
  isAriaLabelDisabled?: boolean
  hasWhiteBackground?: boolean
} & SvgIconProps

const MeaIcon: FC<MeaIconProps> = ({
  severity,
  exponent,
  isAriaLabelDisabled = false,
  hasWhiteBackground = false,
  ...rest
}) => {
  const theme = useTheme()
  const { icon: Icon, ariaLabel } = useSeverityIcon(severity)

  if (!Icon) {
    return null
  }

  return (
    <div css={styles.container}>
      {exponent && (
        <div css={styles.exponentWrapper(theme)}>
          <Typography variant="caption" css={styles.exponent(theme)}>
            {exponent}
          </Typography>
        </div>
      )}

      <SvgIcon component={Icon} css={styles.icon(severity, hasWhiteBackground, theme)} {...rest} />

      {ariaLabel && !isAriaLabelDisabled && <VisuallyHiddenLabel label={ariaLabel} />}
    </div>
  )
}

export default MeaIcon
