import { FC, HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'

import Separator from '@Components/designSystem/foundations/shapes/separator/Separator'

import InputPlace from '../atoms/InputPlace'
import Swap, { SwapProps } from '../atoms/Swap'
import { ODSearchCommonProps } from '../common/ODSearch.types'
import * as commonStyles from '../common/styles'

import * as styles from './styles'

export type ODSearchProps = ODSearchCommonProps

const ODSearch: FC<ODSearchProps & HTMLAttributes<HTMLDivElement>> = ({
  elevation = 'onBackground',
  originInput: { value: originInputValue, error: hasOriginInputError, ...restOriginInput },
  destinationInput: { value: destinationInputValue, error: hasDestinationInputError, ...restDestinationInput },
  onSwap,
  swapProps,
  ...rest
}) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  const handleSwap: SwapProps['onClick'] =
    onSwap &&
    (() => {
      onSwap(
        { origin: originInputValue, destination: destinationInputValue },
        { origin: destinationInputValue, destination: originInputValue }
      )
    })

  return (
    <div {...rest} css={commonStyles.odsearch}>
      <InputPlace
        css={[
          commonStyles.inputPlace(theme, {
            disabled: false,
            hasError: hasOriginInputError,
            isSwapHidden: !onSwap,
          }),
          commonStyles.originInputPlace,
          styles.odSearchInputPlace,
        ]}
        {...restOriginInput}
        value={originInputValue}
        required={!originInputValue}
        prefix={formatMessage({ id: 'departure' })}
        placeholder={formatMessage({ id: 'odSearch_origin_hint_SrLabel' })}
      />
      <InputPlace
        css={[
          commonStyles.inputPlace(theme, {
            disabled: false,
            hasError: hasDestinationInputError,
            isSwapHidden: !onSwap,
          }),
          commonStyles.destinationInputPlace,
          styles.odSearchInputPlace,
        ]}
        {...restDestinationInput}
        value={destinationInputValue}
        required={!destinationInputValue}
        prefix={formatMessage({ id: 'arrival' })}
        placeholder={formatMessage({ id: 'odSearch_destination_hint_SrLabel' })}
      />
      <Separator
        css={commonStyles.separator}
        background={elevation === 'onBackground' ? 'default' : 'paper'}
        isNotOutline
      />
      {onSwap && (
        <Swap
          {...swapProps}
          css={commonStyles.swap}
          ariaLabelOrigin={originInputValue}
          ariaLabelDestination={destinationInputValue}
          data-cs-override-id="recherche-od-switch"
          onClick={handleSwap}
        />
      )}
    </div>
  )
}

export default ODSearch
