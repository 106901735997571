import { FC, ReactNode, useEffect, useRef } from 'react'
import { useTheme } from '@mui/material/styles'

import AppBar from '@Components/designSystem/navigation/appBar/AppBar'

import { useModalPageInfo } from './ModalContext'
import * as styles from './styles'

export type ModalPageProps = {
  headerTitle: string
  children?: ReactNode
  onBackButtonClick?: () => void
}

const ModalPage: FC<ModalPageProps> = ({ headerTitle, children, onBackButtonClick }) => {
  const theme = useTheme()
  const { isBackIconDisplayed, onBackIconClick, onClose } = useModalPageInfo()
  const modalPageContentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    modalPageContentRef.current?.focus()
  }, [])

  return (
    <>
      {headerTitle && (
        <AppBar
          title={headerTitle}
          isInModal
          onCloseButtonClick={onClose}
          {...(isBackIconDisplayed ? { onBackButtonClick: onBackButtonClick || onBackIconClick } : {})}
        />
      )}

      <div css={styles.contentWrapper(theme)} ref={modalPageContentRef} tabIndex={-1}>
        {children}
      </div>
    </>
  )
}

export default ModalPage
