import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SPACING_1 } from '@Styles/constants/spacing'

export const helperTextAlertIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.palette.error.main,
    marginRight: SPACING_1,
    minWidth: rem(16),
    ...size(rem(16)),
  })

export const helperTextMessage = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.error.main,
    display: 'flex',
    fill: theme.palette.error.main,
    margin: `${SPACING_1} 0`,
    paddingLeft: 0,
  })
