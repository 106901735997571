import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SPACING_0, SPACING_1, SPACING_2 } from '@Styles/constants/spacing'
import { truncate } from '@Styles/truncate'
import { SeverityType } from '@Types/severity'
import { getColorBySeverity } from '@Utils/severity'

export const title = (severity: SeverityType, theme: Theme): SerializedStyles =>
  css({
    color: getColorBySeverity(theme, severity),
    marginBottom: SPACING_0,
  })

export const flexContainer = css({
  display: 'flex',
})

export const textContainer = css({
  flexDirection: 'column',
})

export const content = css({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 1,
})

export const description = css(truncate(2))

export const icon = (isDesktopMode: boolean): SerializedStyles =>
  css({
    flexShrink: 0,
    marginRight: SPACING_1,
    marginTop: rem(-1),
    ...size(rem(24)),
    ...(isDesktopMode && {
      marginLeft: SPACING_1,
    }),
  })

export const buttonWrapper = css({
  alignItems: 'center',
  display: 'flex',
  flexShrink: 0,
})

export const action = (isDesktopMode: boolean): SerializedStyles =>
  css({
    marginBottom: `-${SPACING_2}`,
    marginLeft: `-${SPACING_2}`,
    ...(isDesktopMode
      ? {
          justifyContent: 'center',
          margin: 0,
        }
      : {
          alignSelf: 'flex-start',
        }),
  })
