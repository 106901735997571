import { forwardRef, ReactNode, Ref } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectProps, Typography } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'

import { getNavigationAndActionIcon } from '@Components/designSystem/icons/navigationAndActionIcons'
import FormErrorHelper from '@Components/utils/errorHelper/FormErrorHelper'
import useUuid from '@Hooks/useUuid'
import { renderInputLabel } from '@Utils/inputs'

import { DropdownOption, DropdownOptionDefaultValueType } from './Dropdown.types'
import * as styles from './styles'

export type DropdownProps<DropdownValueType = DropdownOptionDefaultValueType> = SelectProps<DropdownValueType> & {
  helperText?: ReactNode
  isLabelHidden?: boolean
  options: DropdownOption<DropdownValueType>[]
  required?: boolean
  renderMenuItem?: (option: DropdownOption<DropdownValueType>, theme: Theme) => JSX.Element
}

const ArrowDownIcon = getNavigationAndActionIcon('arrow_down')

function renderMenuItemDefault<DropdownValueType>(
  { label, value, disabled, Icon }: DropdownOption<DropdownValueType>,
  theme: Theme
) {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <MenuItem disableRipple key={label} value={value as any} disabled={disabled}>
      <div css={styles.dropdownMenuItem}>
        {Icon && <Icon css={styles.dropdownMenuItemIcon(theme)} />}
        <Typography css={styles.dropdownMenuItemText} component="span">
          {label}
        </Typography>
      </div>
    </MenuItem>
  )
}

const Dropdown = forwardRef(
  <DropdownValueType,>(
    {
      label: labelProps,
      id,
      helperText,
      isLabelHidden = false,
      options,
      required = false,
      renderMenuItem = renderMenuItemDefault,
      IconComponent = ArrowDownIcon,
      MenuProps,
      ...rest
    }: DropdownProps<DropdownValueType>,
    ref: Ref<unknown>
  ): JSX.Element => {
    const theme = useTheme()
    const helperTextId = `${rest.name}-dropdown-text-helper`
    const label = labelProps && renderInputLabel(labelProps, required)

    const inputId = useUuid()

    return (
      <FormControl css={styles.root(theme, !!rest.value)} fullWidth>
        {!isLabelHidden && (
          <InputLabel disabled={rest.disabled} id={`${id || inputId}-label`} error={!!helperText}>
            <Typography>{label}</Typography>
          </InputLabel>
        )}
        <Select
          {...rest}
          id={id}
          IconComponent={IconComponent}
          css={styles.select(theme)}
          error={!!helperText}
          labelId={label ? `${id || inputId}-label` : undefined}
          label={!isLabelHidden ? label : undefined}
          MenuProps={{
            id: `${id || inputId}-options`,
            sx: styles.menu(theme),
            ...MenuProps,
          }}
          inputRef={ref}
        >
          {options.map((option) => renderMenuItem(option, theme))}
        </Select>
        {helperText && (
          <FormErrorHelper id={helperTextId} dataTest={helperTextId} helperText={helperText} error={!!helperText} />
        )}
      </FormControl>
    )
  }
)

export default Dropdown
