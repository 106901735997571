import { ReactElement } from 'react'
import { Controller, RegisterOptions } from 'react-hook-form'

import Dropdown, { DropdownProps } from '@Components/designSystem/forms/dropdown/Dropdown'
import { DropdownOption } from '@Components/designSystem/forms/dropdown/Dropdown.types'

type ControlDropdownProps<TDropdownOption extends DropdownOption, VDropdownOptionValue = TDropdownOption['value']> = {
  id?: string
  name: string
  options: TDropdownOption[]
  rules?: RegisterOptions
  onChange?: (value: VDropdownOptionValue) => void
} & Omit<DropdownProps, 'onChange'>

const ControlDropdown = <TDropdownOption extends DropdownOption>({
  name,
  rules,
  options,
  id,
  onChange,
  ...rest
}: ControlDropdownProps<TDropdownOption>): ReactElement => (
  <Controller
    name={name}
    rules={rules}
    defaultValue={rest.defaultValue}
    render={({ field: { value, onChange: onChangeField, ref }, fieldState: { error } }) => (
      <Dropdown
        {...rest}
        ref={ref}
        id={id}
        required={!!rules?.required}
        options={options}
        helperText={!!error && error.message}
        value={value}
        onChange={(event) => {
          onChangeField(event.target.value)
          onChange?.(event.target.value)
        }}
      />
    )}
  />
)

export default ControlDropdown
