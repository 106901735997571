import { css } from '@emotion/react'
import { rem } from 'polished'

import { SPACING_4 } from '@Styles/constants/spacing'

import { DATEPICKER_GRID_CELL_SIZE } from '../day/styles'

export const root = css({
  margin: '0 auto',
  maxWidth: rem(320),
  width: '100%',
})

export const title = css({
  marginBottom: rem(7),
  padding: `0 ${rem(10)}`,
})

export const grid = css({
  alignItems: 'center',
  display: 'grid',
  gridAutoRows: DATEPICKER_GRID_CELL_SIZE,
  gridGap: `${rem(7)} 0`,
  gridTemplateColumns: 'repeat(7, 1fr)',
  marginBottom: SPACING_4,
})

export const dayTitle = css({
  textAlign: 'center',
})
