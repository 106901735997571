import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { SPACING_2, SPACING_4, SPACING_6 } from '@Styles/constants/spacing'

export const longDistanceDatePickerDialog = {
  maxHeight: '100%',
  maxWidth: rem(864),
}

export const longDistanceDatePickerFormRoot = css({
  display: 'flex',
  flexDirection: 'column',
})

export const controlsContainer = (theme: Theme, isInModal = false): SerializedStyles =>
  css({
    ...(!isInModal && {
      [theme.breakpoints.up('lg')]: {
        gridGap: SPACING_6,
        gridTemplateColumns: '1fr 1fr',
        paddingLeft: SPACING_6,
        paddingRight: SPACING_6,
      },
    }),
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    gridGap: SPACING_2,
    gridTemplateColumns: '1fr',
    padding: `${SPACING_2} ${rem(10)}`,
  })

export const controlsRow = css({
  display: 'flex',
  justifyContent: 'space-between',
})

export const datePickerContainer = (theme: Theme): SerializedStyles =>
  css({
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    paddingTop: SPACING_2,
    [theme.breakpoints.up('lg')]: {
      height: 'auto',
    },
  })

export const controlInputDate = css({
  flexGrow: 1,
})

export const controlDropdown = css({
  flexBasis: rem(92),
  flexShrink: 0,
  marginLeft: SPACING_2,
})

export const buttonWrapper = css({
  display: 'flex',
  justifyContent: 'center',
  padding: SPACING_4,
})
