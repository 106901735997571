import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { Z_INDEX_HIGH } from '@Styles/constants/layout'
import { STANDARD_RADIUS } from '@Styles/constants/radius'
import { SPACING_1, SPACING_2, SPACING_3, SPACING_4, SPACING_5 } from '@Styles/constants/spacing'

export const sncfConnectWidgetBookingContainer = (theme: Theme): SerializedStyles =>
  css({
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.customPalette.onElements.outline}`,
    borderRadius: `0 0 ${STANDARD_RADIUS} ${STANDARD_RADIUS}`,
  })

export const sncfConnectWidgetBookingForm = css({
  padding: `${SPACING_3} ${SPACING_2} ${SPACING_5} ${SPACING_2}`,
})

export const sncfConnectWidgetBookingFormTitle = css({
  paddingBottom: SPACING_3,
})

export const sncfConnectDateContainer = css({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: SPACING_4,
})

export const sncfConnectErrorMessage = css({
  marginBottom: SPACING_2,
})

export const bookingODSearchController = css({
  marginBottom: SPACING_1,
})

export const validateButton = css({
  display: 'flex',
  justifyContent: 'center',
})

export const autocompleteContainer = css({
  marginTop: SPACING_1,
  position: 'relative',
})

export const autocomplete = (theme: Theme): SerializedStyles =>
  css({
    border: `1px solid ${theme.customPalette.onElements.outline}`,
    maxHeight: rem(500),
    overflow: 'auto',
    position: 'absolute',
    zIndex: Z_INDEX_HIGH,
  })
