import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'

import { SPACING_1, SPACING_2, SPACING_3 } from '@Styles/constants/spacing'

export const noResultTitle = css({
  paddingBottom: SPACING_1,
})

export const noResultText = (theme: Theme): SerializedStyles =>
  css({
    padding: `0 ${SPACING_2}`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: `0 ${SPACING_3}`,
    },
  })
