import { FC } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { differenceInCalendarDays } from 'date-fns'

import DatePicker, { DatePickerProps } from '@Components/designSystem/components/datePicker/DatePicker'
import { transformDate } from '@Utils/datepicker'

import { LongDistanceDatePickerFormData } from '../LongDistanceDatePicker.types'

export type ControlDatePickerProps = Pick<
  DatePickerProps,
  'initTarget' | 'maxDate' | 'mode' | 'numberOfMonths' | 'onChange' | 'restrictedDatesList'
> & {
  control: Control<LongDistanceDatePickerFormData>
} & Partial<Pick<DatePickerProps, 'scrollElementRef'>>

const ControlDatePicker: FC<ControlDatePickerProps> = ({
  control,
  numberOfMonths,
  onChange,
  restrictedDatesList,
  scrollElementRef,
  ...rest
}) => {
  const [startDate, endDate] = useWatch<LongDistanceDatePickerFormData>({
    control,
    name: ['startDate', 'endDate'],
  })

  const transformedStartDate = transformDate(startDate)
  const transformedEndDate = transformDate(endDate)

  const hasEndDate = endDate && differenceInCalendarDays(transformedStartDate, transformedEndDate) <= 0

  return (
    <DatePicker
      {...rest}
      endDate={hasEndDate ? transformedEndDate : undefined}
      numberOfMonths={numberOfMonths}
      onChange={onChange}
      restrictedDatesList={restrictedDatesList}
      startDate={startDate ? transformedStartDate : undefined}
      scrollElementRef={scrollElementRef}
    />
  )
}

export default ControlDatePicker
