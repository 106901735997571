import { ElementType } from 'react'

import Separator from '@Components/designSystem/foundations/shapes/separator/Separator'

import { ButtonProps } from '../Button'
import ButtonsGroup from '../buttonsGroup/ButtonsGroup'

export type BottomActionsProps<
  PrimaryComponentType extends ElementType = 'button',
  SecondTypeComponentType extends ElementType = 'button'
> = {
  primary: ButtonProps<PrimaryComponentType>
  secondary?: ButtonProps<SecondTypeComponentType>
}

const BottomActions = <
  PrimaryComponentType extends ElementType = 'button',
  SecondTypeComponentType extends ElementType = 'button'
>({
  primary,
  secondary,
}: BottomActionsProps<PrimaryComponentType, SecondTypeComponentType>): JSX.Element => (
  <>
    <Separator isNotOutline background="paper" />
    <ButtonsGroup primary={primary} secondary={secondary} variant="BOTTOM_ACTIONS" />
  </>
)

export default BottomActions
