import { FC } from 'react'
import { useTheme } from '@mui/material/styles'
import { COLORS } from 'src/widgets-external/booking/_internal/BookingHeader.types'
import * as styles from 'src/widgets-external/booking/_internal/styles'

import BrandLogo from '@Components/designSystem/medias/logo/BrandLogo'

type BookingHeaderProps = {
  color?: COLORS
}

const BookingHeader: FC<BookingHeaderProps> = ({ color }) => {
  const theme = useTheme()

  return (
    <div css={styles.bookingHeader(theme, color)}>
      <div css={styles.titleContainer}>
        <BrandLogo />
      </div>
    </div>
  )
}

export default BookingHeader
