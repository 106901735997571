import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  eachDayOfInterval,
  getHours,
  isSameDay,
  isWeekend,
  setDate,
} from 'date-fns'

import { DropdownOption } from '@Components/designSystem/forms/dropdown/Dropdown.types'

export const generateHours = (startHour = 4): DropdownOption[] =>
  [...Array(10).keys()]
    .map((i) => ({
      value: (2 * i + 4).toString(),
      label: `${(2 * i + 4).toString()}h`,
    }))
    .filter(({ value }) => +value >= startHour)

export const getDateHourWithDefault = (date?: Date, defaultValue = '6'): string => {
  if (date) {
    const hours = getHours(date)

    return `${hours - (hours % 2)}`
  }

  return defaultValue
}

export const transformDate = (dateString: string): Date => {
  if (!dateString) {
    return new Date('invalid date')
  }
  const dateParts = dateString.split(dateString.includes('-') ? '-' : '/')

  if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2])
  }

  if (dateString.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])
  }

  return new Date('invalid date')
}

export const isDateOutsideRange = (day: Date, minDate: Date, maxDate?: Date): boolean =>
  differenceInCalendarDays(day, minDate) < 0 || (!!maxDate && differenceInCalendarDays(day, maxDate) > 0)

export const isDateOutsideRestrictedList = (day: Date, restrictedDatesList?: Date[]): boolean =>
  !!restrictedDatesList && restrictedDatesList.find((restrictedDate) => isSameDay(day, restrictedDate)) === undefined

export const getDefaultMaxDate = (): Date => addDays(setDate(addMonths(new Date(), 12), 1), -1)

export const getBusinessDates = (startDate: Date, endDate: Date): Date[] =>
  eachDayOfInterval({ start: startDate, end: endDate }).filter((day) => !isWeekend(day))
