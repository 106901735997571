import { CSSProperties } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { APPBAR_HEIGHT, APPBAR_MD_HEIGHT } from '@Styles/constants/layout'
import { SPACING_2, SPACING_5 } from '@Styles/constants/spacing'

export const page = (theme: Theme, sizePage: 'small' | 'large'): SerializedStyles =>
  css({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxHeight: '-webkit-fill-available',
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: rem(830),
      width: sizePage === 'small' ? rem(560) : '100vw',
    },
  })

export const contentWrapper = (theme: Theme): SerializedStyles =>
  css({
    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${APPBAR_HEIGHT})`,
    outline: 0,
    [theme.breakpoints.up('md')]: {
      height: `calc(100% - ${APPBAR_MD_HEIGHT})`,
    },
  })

export const scrollableContent = css({
  flex: 1,
  overflow: 'auto',
})

export const content = (theme: Theme, hasNoContentSpacing: boolean): SerializedStyles =>
  css({
    ...(!hasNoContentSpacing && {
      padding: SPACING_2,
      [theme.breakpoints.up('md')]: {
        padding: SPACING_5,
      },
    }),
  })

export const paper: CSSProperties = {
  overflowY: 'unset',
}
